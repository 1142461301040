<template>
  <div>
    <div class="kt-login__head"></div>
    <!--begin::Body-->
    <div class="kt-login__body">
      <div class="kt-login__form">
        <div class="kt-login__title">
          <h3></h3>
        </div>

        <!--begin::Form-->
        <b-form class="kt-form" @submit.stop.prevent="onSubmit">
          <h5>
            {{ $t("AUTH.RESET_PASSWORD.DESC") }}
          </h5>
          <p class="my-4 text-center" v-if="!isCreated">
            <b-button @click="getNewPassword()">{{
              $t("AUTH.RESET_PASSWORD.GENERATE_NEW_PASSWORD")
            }}</b-button>
          </p>
          <p class="kt-error_description">{{ 
            newPass 
          }}
          <b-button 
            @click="copyPassword()"
            v-if="isCreated"
            class="ml-5"
          >{{
            $t("COMMON.COPY_CLIPBOARD")
          }}
          </b-button>
          </p>
          <p class="my-4 text-center" v-if="isCreated">
            <b-button @click="goFrontPage()">{{
              $t("AUTH.RESET_PASSWORD.GO_LOGIN")
            }}</b-button>
          </p>
        </b-form>
        <!--end::Form-->
      </div>
    </div>
    <!--end::Body-->
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/error/error-3.scss";
</style>

<script>
import { postRequest } from "@/api/request.js";
import copy from "clipboard-copy";
export default {
  name: "ForgotPass",
  data: () => ({
    newPass: "",
    newPassClipboard: "",
    isCreated: false,
  }),
  mounted() {},
  methods: {
    getNewPassword() {
      this.newPassClipboard = "";
      postRequest({
        action: "startResetAdminPassword",
        ref: this.$route.query.ref,
      }).then((res) => {
        if (res && res.returncode) {
          this.isCreated = true;
          this.newPassClipboard = res.returndata[1];          
          this.newPass = res.returndata[0];
        } else {
          this.newPass = res.returndata;
        }
        // Success or error we get from backend
      });
    },
    goFrontPage() {
      this.$router.push("/login");
    },
    copyPassword() {
      copy(this.newPassClipboard);
    },
  },
  
};
</script>
